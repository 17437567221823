<template>
  <div class="container-md mt-4">
    
    <BorderSpinner :isVisible="isLoadingMeldingen" />

    <div class="row justify-content-center">
      <div class="col col-meldingen">
      <table class="table">
        <tbody>
          <tr v-for="(melding, index) in customItems" :key="index" @click="selectMelding(melding)">
            <td>{{ melding.memoShorted }}</td>
            <td class="col-date">{{ melding.relativeDateTime }}</td>
            <td v-show="melding.opvolgen" class="col-opvolgen">!</td>
            <td v-show="!melding.opvolgen" class="col-opvolgen">&nbsp;</td>
          </tr>
        </tbody>
        </table>
      </div>
    </div>
    
    <div class="row justify-content-center" v-show="!isLoadingMeldingen && meldingen.length == 0">
      <div class="col-10 text-center">
        <p>Er zijn geen recenten meldingen.</p>
      </div>
    </div>
  </div>
</template>

<script>
import BorderSpinner from '@/components/BorderSpinner.vue'
import { useMeldingStore } from '@/stores';
import { fetchWrapper } from '@/fetch-wrapper';

export default {
  name: 'TodayView',
  components: {
    BorderSpinner
  },
  data() {
    return {
      isLoadingMeldingen: true,
      meldingen: []
    }
  },
  async mounted() {
    // from web
    // and from local
    this.meldingen = [];
        
    const baseUrl = process.env.VUE_APP_API_BASE_URL;
    try {
      const response = await fetchWrapper.get(`${baseUrl}/meldingen/recent`);
      this.meldingen = response.today;
      if (this.meldingen.length === 0) {
        this.meldingen = response.yesterday;

        if (this.meldingen.length === 0) {
          this.meldingen = response.recent;
        }
      }

      this.isLoadingMeldingen = false;
    }
    catch (error) {
      console.error(error);

      this.isLoadingMeldingen = false;

      // TODO: alert
    }
  },
  methods: {
    selectMelding(melding) {
      const meldingStore = useMeldingStore();      
      meldingStore.setSelectedMelding(melding);

      this.$router.push('/melding-detail/' + melding.meldingId)
    }
  },
  computed: {
    customItems() {
      return this.meldingen.map(item => {
        if (item.memo.length > 40) {
          return {...item, memoShorted:item.memo.substring(0, 40) + '...'}
        }
        return {...item, memoShorted:item.memo}
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.col-meldingen {
  padding: 0;
}

.table {
  margin-bottom: 0;

  td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  tr:last-child td {    
    border-bottom-width: 2px;
  }
}

.col-date {
  text-align: right;
}
td.col-opvolgen {
  width: 20px;
  padding-left: 0;
}
</style>