<template>

  <div class="container-md mt-4">
    <div class="row justify-content-center">
      <div class="col col-meldingen">
        <div class="mb-3">
          <label class="form-label">Type melding</label>
          <p>{{ melding.type }}</p>
        </div>
        <div class="mb-3">
          <label class="form-label">Tijdstip</label>
          <p>{{ melding.relativeDateTime }}</p>
        </div>
        <div class="mb-3" v-if="melding.type == 'praatje-vraag' || melding.type == 'signaal-probleem'">
          <label class="form-label">Doelgroep</label>
          <p>{{ melding.doelgroep }}</p>
        </div>
        <div class="mb-3" v-if="melding.type == 'praatje-vraag' || melding.type == 'signaal-probleem'">
          <label class="form-label">Leefgebied</label>
          <p>{{ melding.leefgebied }}</p>
        </div>
        <div class="mb-3" v-if="melding.opvolgen">
          <label class="form-label">Opvolgen</label>
          <p>Deze melding is verstuurd voor opvolging.</p>
        </div>
        <div class="mb-3" v-if="(melding.type == 'praatje-vraag' || melding.type == 'signaal-probleem') && !melding.opvolgen">
          <label class="form-label">Opvolgen</label>
          <p>Deze meling hoeft niet te worden opgevolgd.</p>
        </div>
        <div class="mb-3" v-if="melding.type == 'praatje-vraag' || melding.type == 'signaal-probleem'">
          <label class="form-label">Opmerking</label>
          <p>{{ melding.memo }}</p>
        </div>
        <div class="mb-3" v-if="(melding.type == 'praatje-vraag' || melding.type == 'signaal-probleem')">
          <a class="btn btn-secondary" href="#" @click="edit()">Opmerking aanpassen</a>
        </div>
      </div>
    </div>
  
    <nav class="navbar navbar-expand fixed-bottom navbar-previous">
      <div class="container-fluid">
        <div class="navbar-nav d-flex justify-content-evenly">
          <a class="btn btn-secondary" href="#" @click="previous()">Vorige</a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'MeldingDetailView',
  data() {
    return {
      melding: {
        type: 'Onbekend'
      }
    }
  },
  mounted() {
    const meldingStore = useMeldingStore();
    this.melding = meldingStore.getSelectedMelding();    

    console.log("melding.type" + this.melding.type)
  },
  unmounted() {
    this.melding = {
        type: 'Onbekend'
    };
  },
  methods: {
    previous() {
      this.$router.push('/today')
    },
    edit() {
      this.$router.push('/melding-edit')
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.form-label {
  margin-bottom: 0;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar-previous {
  margin-bottom: calc(env(safe-area-inset-bottom) + 26px);
}
</style>