import { defineStore } from 'pinia';
import { fetchWrapper } from '@/fetch-wrapper';

const baseUrl = process.env.VUE_APP_API_BASE_URL;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        authInfo: JSON.parse(localStorage.getItem('auth-info')),
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(email, password) {
            const authInfo = await fetchWrapper.post(`${baseUrl}/login`, { email, password });

            this.authInfo = authInfo;
            localStorage.setItem('auth-info', JSON.stringify(authInfo));

            const user = await fetchWrapper.get(`${baseUrl}/gebruikers/${encodeURIComponent(email)}`);
            user.lastRetrieved = new Date();
            this.user = user;

            localStorage.setItem('user', JSON.stringify(user));
        },
        async refressAccessToken() {
            const body = {
                "refreshToken": this.authInfo.refreshToken
            };
            const authInfo = await fetchWrapper.post(`${baseUrl}/refresh`, body);
            this.authInfo = authInfo;
            localStorage.setItem('auth-info', JSON.stringify(authInfo));
        },
        logout() {
            this.authInfo = null;
            localStorage.removeItem('auth-info');
            
            this.user = null;
            localStorage.removeItem('user');
        }
    }
});