<template>
  <div class="container-md mt-4">

    <h3>Doelgroep</h3>

    <form v-on:submit.prevent="onSubmit">
      <div class="list-group">
        <a href="#" class="list-group-item list-group-item-action" @click="select('Jeugd / Jongeren')">Jeugd / Jongeren</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Volwassene')">Volwassene</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Senior')">Senior</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Netwerkpartner / Middenstander')">Netwerkpartner / Middenstander</a>
      </div>
    </form>
    
  <nav class="navbar navbar-expand fixed-bottom">
    <div class="container-fluid">
      <div class="navbar-nav d-flex justify-content-evenly">
        <a class="btn btn-secondary" href="#" @click="previous()">Vorige</a>
      </div>
    </div>
  </nav>
  </div>
  
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'AddMeldingDoelgroepView',
  methods: {
    previous() {
      const meldingStore = useMeldingStore();
      meldingStore.moveBackCurrentMelding();
      
      this.$router.push('/add-melding')
    },
    select(doelgroep) {
      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
      currentMelding.doelgroep = doelgroep;
      meldingStore.setCurrentMelding(currentMelding);

      this.$router.push('/add-melding/leefgebied')      
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.navbar {
  .navbar-nav {
    padding-bottom: calc(env(safe-area-inset-bottom) + 26px);
  }
}
</style>