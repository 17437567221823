<template>
  <nav class="navbar navbar-expand fixed-bottom">
    <div class="container-fluid">
      <div class="navbar-nav d-flex justify-content-evenly">
        <a class="nav-link home-link " href="#" @click="home"><i class="fas fa-home fa-lg mb-1"></i>Home</a>
        <a class="nav-link vandaag-link" href="#" @click="today">Vandaag</a>
        <a class="nav-link profiel-link" href="#" @click="profile">Profiel</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'FooterBar',
  data() {
    return {
    }
  },
  methods: {
    home() {
      this.$router.push('/home/');
    },
    today() {
      this.$router.push('/today');
    },
    profile() {
      this.$router.push('/profile');
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.navbar {
  padding-top: 11px;
  background-color: @main-color;

  .navbar-nav {
    width: 100%;
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
  }

  .nav-link {
    padding-top: 26px;
    padding-bottom: 0;
    background-position: top center;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 0.75rem;
    
    &.active {
      color: #fff;
    }
  }
}

.home-link {
  background-image: url(./../assets/icon-home.png);
  
  &:hover, &.active {
    background-image: url(./../assets/icon-home-active.png);
  }
}

.vandaag-link {
  background-image: url(./../assets/icon-vandaag.png);  

  &:hover, &.active {
    background-image: url(./../assets/icon-vandaag-active.png);
  }
}
.profiel-link {
  background-image: url(./../assets/icon-profiel.png);

  &:hover, &.active {
    background-image: url(./../assets/icon-profiel-active.png);
  }
}
</style>
