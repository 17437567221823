<template>
  <div class="alert alert-primary hidden" role="alert" v-show="isVisible">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AlertBar',
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      show: Boolean,
      alert
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

// TODO: Hide animation
.alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 1rem;
    margin-bottom: 0;
    border-width: 0;
    border-radius: 0;
    text-align: center;
    
    &.alert-primary {
        border-top-width: 0;
        background-color: @darker-accent-color;
        color: white; 
    }
}

</style>
