import { defineStore } from 'pinia';

export const useMeldingStore = defineStore({
    id: 'melding',
    state: () => ({
        isCancelled: false,
        moveBack: false
    }),
    actions: {
        setCurrentMelding(melding) {     
            this.isCancelled = false;

            localStorage.setItem('currentMelding', JSON.stringify(melding));
        },
        getCurrentMelding() {
            return JSON.parse(localStorage.getItem('currentMelding'));
        },
        cancelCurrentMelding() {
            this.isCancelled = true;
        },
        moveBackCurrentMelding() {
            this.moveBack = true;
        },
        setSelectedMelding(melding) {            
            localStorage.setItem('selectedMelding', JSON.stringify(melding));
        },
        getSelectedMelding() {
            return JSON.parse(localStorage.getItem('selectedMelding'));
        }
    }
});