<template>  
  <HeaderBar :title="headerTitle" :cancelMeldingEnabled="cancelMeldingEnabled" 
  :saveMeldingEnabled="saveMeldingEnabled" :sendMeldingEnabled="sendMeldingEnabled" 
  :cancelMeldingEditEnabled="cancelMeldingEditEnabled" :saveMeldingEditEnabled="saveMeldingEditEnabled"
  v-if="showHeader" />

  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition || ''" :mode="route.meta.mode || ''">
      
        <component :is="Component"></component>
      
    </transition>
  </router-view>

  <FooterBar v-if="showFooter" />
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import { useInstallStore } from '@/stores';

export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar
  },
  created() {    
    window.addEventListener('beforeinstallprompt', (e) => {
        console.log('beforeinstallprompt')

        e.preventDefault()
        
        const installStore = useInstallStore();
        installStore.setCurrentInstall(e);
    })

    try
    {
      screen.orientation.lock("portrait")
        .then(() => {
          console.log('Locked to "portrait"');
        })
        .catch((error) => {
          console.log(error);
        });
    }
    catch (error) {
      console.log(error);
    }
  },
  computed: {
    showFooter() {
      return this.$route.meta.footer || false;
    },
    showHeader() {
      return this.$route.meta.header || true;
    },
    headerTitle() {
      return this.$route.meta.headerTitle;
    },
    cancelMeldingEnabled() {
      return this.$route.meta.cancelMeldingEnabled || false;
    },
    saveMeldingEnabled() {
      return this.$route.meta.saveMeldingEnabled || false;
    },
    sendMeldingEnabled() {
      return this.$route.meta.sendMeldingEnabled || false;
    },
    cancelMeldingEditEnabled() {
      return this.$route.meta.cancelMeldingEditEnabled || false;
    },
    saveMeldingEditEnabled() {
      return this.$route.meta.saveMeldingEditEnabled || false;
    }
  }
}
</script>

<style lang="less">
@font-face {  
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Roboto"),url(./assets/fonts/Roboto-Regular.ttf) format("truetype"), url("./assets/fonts/Roboto-Regular.woff2") format("woff2"), url("./assets/fonts/Roboto-Regular.woff") format("woff");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease-out;
}

.slide-left-enter-to {
  position: absolute;
  left: 0;
}

.slide-left-enter-from {
  position: absolute;
  left: -100%;
}

.slide-left-leave-to {
  position: absolute;
  right: -100%;
}

.slide-left-leave-from {
  position: absolute;
  right: 0;
}

.slide-up-enter-from,
.slide-up-leave-to {
	transform: translateY(300px);
}

.slide-up-enter-active {
	transition: 0.3s ease-out;
}
.slide-up-leave-active {
	transition: 0;
}

.slide-up-enter-from {
  opacity: 0;
}
.slide-up-leave-to {
  opacity: 0;
	transform: translateY(50%);
}

.slide-down-enter-from,
.slide-down-leave-to {
	transform: translateY(-300px);
}

.slide-down-enter-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.slide-down-leave-active {
	transition: 0.3s ease-out;
}

.slide-down-enter-from {
  opacity: 0;
}
.slide-down-leave-to {
  opacity: 0;
	transform: translateY(50%);
}
</style>
