<template>
  <div class="container-md mt-4">

    <h3>Contactgegevens</h3>

    <form v-on:submit.prevent="onSubmit">
      <div class="mb-3">
        <input type="text" placeholder="Naam" maxlength="50" class="form-control" v-model.lazy="naam" />
      </div>
      <div class="mb-3">
        <textarea rows="8" placeholder="Adres" class="form-control" v-model.lazy="adres"></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'AddMeldingContactgegevensView',
  data() {
    return {
      naam: '',
      adres: ''
    }
  },
  watch: {
    naam(newValue) {      
      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
      currentMelding.naam = newValue;
      meldingStore.setCurrentMelding(currentMelding);
    },
    adres(newValue) {      
      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
      currentMelding.adres = newValue;
      meldingStore.setCurrentMelding(currentMelding);
    }
  },
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";
</style>