<template>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status" v-show="isVisible">
        <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BorderSpinner',
  props: {
    isVisible: Boolean
  }
}
</script>