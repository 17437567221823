<template>
  <div class="container-md mt-4">

    <h3>Opvolgen</h3>

    <p>Wanneer je voor opvolgen kiest, ontvang je de melding in je email. Deze informatie wordt niet opgeslagen.</p>

    <form v-on:submit.prevent="onSubmit">
      <div class="list-group">
        <a href="#" class="list-group-item list-group-item-action" @click="opvolgen()">Melding opvolgen</a>
        <a href="#" class="list-group-item list-group-item-action" @click="dismiss()">Geen opvolging nodig</a>
      </div>
    </form>
  </div>
  
</template>

<script>
export default {
  name: 'AddMeldingOpvolgenView',
  methods: {
    dismiss() {
      this.$router.push('/home/')
    },
    opvolgen() {
      this.$router.push('/add-melding/contactgegevens')      
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.container-md p {
  font-style: italic;
}

</style>