<template>
  <div class="container-md mt-4">
    <div class="row row-naam justify-content-center">
      <div class="col-10 text-center">
        <h2>{{ firstName }} {{ lastName }}</h2>

        <p></p>
      </div>
    </div>
    <div class="row row-uitloggen justify-content-center row-buttons">
        <div class="col-10 d-flex justify-content-center">
                    
          <button class="btn btn-primary" @click="logout">Uitloggen</button>

        </div>
      </div>

    
  </div>
</template>

<script>
import { useAuthStore } from '@/stores';

export default {
  name: 'ProfileView',
  data() {
    return {
      firstName: '',
      lastName: 'Onbekend'
    }
  },
  created() {
    const { user } = useAuthStore();
    this.firstName = user.voornaam;
    this.lastName = user.achternaam;
  },
  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.logout();

      this.$router.push('/login');
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.row-naam {
  margin-top: 4em;
}

.row-uitloggen {
  margin-top: 6em;
}
</style>