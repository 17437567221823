<template>
  <div class="container-md mt-4">

    <h3>Opmerking</h3>

    <form v-on:submit.prevent="onSubmit">
      <textarea rows="8" placeholder="Voeg een opmerking toe" class="form-control" v-model.lazy="opmerking"></textarea>
    </form>
    
    <nav class="navbar navbar-expand fixed-bottom">
      <div class="container-fluid">
        <div class="navbar-nav d-flex justify-content-evenly">
          <a class="btn btn-secondary" href="#" @click="previous()">Vorige</a>
        </div>
      </div>
    </nav>
  </div>
  
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'AddMeldingOpslaanView',
  data() {
    return {
      titel: '',
      opmerking: ''
    }
  },
  watch: {
    opmerking(newValue) {      
      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
      currentMelding.opmerking = newValue;
      meldingStore.setCurrentMelding(currentMelding);
    },
  },
  methods: {
    previous() {
      const meldingStore = useMeldingStore();
      meldingStore.moveBackCurrentMelding();
      
      this.$router.push('/add-melding/leefgebied')
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.navbar {
  .navbar-nav {
    padding-bottom: calc(env(safe-area-inset-bottom) + 26px);
  }
}

textarea {
  resize: none;
}
</style>