import { defineStore } from 'pinia';

export const useInstallStore = defineStore({
    id: 'install',
    state: () => ({
        selectedInstall: null
    }),
    actions: {
        setCurrentInstall(event) {     
            this.selectedInstall = event;
        },
        getCurrentInstall() {
            return this.selectedInstall;
        }
    }
});