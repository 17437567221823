<template>

  <div class="start">
    <div class="container">
      <div class="row row-spinner">
        <BorderSpinner :isVisible="isLoading" />
        <br />
        <!--IsStandalone: {{isStandalone}}<br />
        IsDisplayStandalone: {{isDisplayStandalone}}-->
      </div>
    </div>
  </div>

</template>

<script>
import BorderSpinner from '@/components/BorderSpinner.vue'
import { useAuthStore } from '@/stores';

export default {
  name: 'StartView',
  components: {
    BorderSpinner
  },
  data() {
    return {
      isLoading: true,
      isStandalone: false,
      isDisplayStandalone: false
    }
  },
  async created() {  
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      console.log('isStandalone: ' + isStandalone)

      this.isStandalone = window.navigator.standalone;
      this.isDisplayStandalone = isStandalone;

      if (window.navigator.standalone || isStandalone) {
        try {        
          const authStore = useAuthStore();
          console.log('Refresh access token')
          await authStore.refressAccessToken();

          this.isLoading = false;
          
          this.$router.push('/home/')
        }
        catch (error) {
          this.isLoading = false;
                          
          this.$router.push('/login')
        }
        return;
      }
      else {
        console.log('Navigate to permissions')
        this.$router.push('/permissions')      
      }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.row-spinner {
  margin-top: 6rem;
}
</style>