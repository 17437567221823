<template>
    <div class="container-md mt-4">

      <h3>Type melding</h3>

      <div class="list-group">
        <a href="#" class="list-group-item list-group-item-action" @click="select(11)">Praatje / Vraag</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select(12)">Signaal / Probleem</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select(10)">Wijkronde</a>
      </div>
    </div>
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'AddMeldingView',
  components: {
  },
  methods: {
    select(typeMelding) {
      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
      currentMelding.typeMelding = typeMelding;
      meldingStore.setCurrentMelding(currentMelding);

      if (typeMelding == 10) {
        this.$router.push('/add-melding/wijkronde')
      }
      else {
        this.$router.push('/add-melding/doelgroep')
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";
</style>