<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col">
          <p class="hello">Hallo {{ firstName }}</p>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <p>{{ locationMessage }}</p>
        </div>
      </div>
    </div>

    <div class="add-melding-container">
      <button class="btn-add-melding" @click="addMelding"></button>
    </div>
  </div>

  <AlertBar :isVisible="showAlertBar">
    {{alertMessage}}
  </AlertBar>
</template>

<script>
import AlertBar from '@/components/AlertBar.vue'
import { useAuthStore } from '@/stores';
import { useMeldingStore } from '@/stores';

export default {
  name: 'HomeView',
  components: {
    AlertBar
  },
  data() {
    return {
      firstName: '',
      requestLocation: false,
      gettingLocation: false,
      locationMessage: '',
      showAlertBar: false,
      alertMessage: ''
    }
  },
  async created() {
    const { user } = useAuthStore();
    this.firstName = user.voornaam;

    if (!this.$route.params.alert) {
      return;
    }
    
    if (this.$route.params.alert == 'melding-saved')  {      
      this.alertMessage = 'Melding gesynchroniseerd'
    }
    else if (this.$route.params.alert == 'melding-saving-failed')  {      
      this.alertMessage = 'Synchroniseren van de melding mislukt, het wordt later opnieuw geprobeerd.'
    }
    else if (this.$route.params.alert == 'melding-send')  {      
      this.alertMessage = 'Melding verstuurd'
    }
    else if (this.$route.params.alert == 'melding-sending-failed')  {      
      this.alertMessage = 'Versturen van de melding is mislukt, het wordt later opnieuw geprobeerd.'
    }

    this.showAlertBar = true

    setTimeout(() => this.showAlertBar = false, 5000);
  },
  methods: {
    addMelding() {
      try {
        if (!("geolocation" in navigator)) {
          this.locationMessage = 'Uw locatie is niet beschikbaar.';
          return;
        }

        this.locationMessage = 'Bezig met ophalen locatie...';
        this.gettingLocation = true;
          
        const meldingStore = useMeldingStore();
        const options = {
          enableHighAccuracy: false,
          maximumAge: 30000, // Use new location every 30 seconds
          timeout: 27000,
        };
        navigator.geolocation.getCurrentPosition(pos => {
          this.gettingLocation = false;
          
          this.locationMessage = 'Locatie beschikbaar.';

          const melding = {
            position: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude
            }
          };
          meldingStore.setCurrentMelding(melding);
          
          this.$router.push('/add-melding')
        }, err => {
          this.gettingLocation = false;

          this.locationMessage = 'Om de meldingen te voorzien van een locatie, is het nodig toestemming te geven voor het volgen van de locatie van de telefoon.';
          this.locationMessage += ' (' + err.message + ')';

          switch ( err.code ) { 
              case err.PERMISSION_DENIED:                
                  console.error( "User denied the request for Geolocation." ); 
                  break;     
              case err.POSITION_UNAVAILABLE:
                  console.error( "Location information is unavailable." ); 
                  break; 
      
              case err.TIMEOUT:     
                  console.error( "The request to get user location timed out." ); 
                  break; 
      
              case err.UNKNOWN_ERROR:     
                  console.error( "An unknown error occurred." ); 
                  break; 
          }

          console.log(err.message);
        }, options)
      }
      catch (err) {
          console.log(err.message);
          this.locationMessage = 'Uw locatie is niet beschikbaar. (' + err.message + ')';
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.home {
  margin-top: 4rem;
  text-align: center;
}

.hello {
  font-size: 1.2rem;
}

.add-melding-container {
  position: absolute;
  bottom: 8rem;
  left: 50%;
  width: 66px;
  margin-left:-33px;
}

.btn-add-melding {
  height: 66px;
  width: 66px;
  background-color: transparent;
  background-image: url(./../assets/add-melding.png);
  background-repeat: no-repeat;
  border: 0;
}

</style>