<template>

  <div class="container-md mt-4">
    
    <h3>Opmerking</h3>

    <form v-on:submit.prevent="onSubmit">
      <textarea rows="8" placeholder="Voeg een opmerking toe" class="form-control" v-model.lazy="opmerking"></textarea>
    </form>
  </div>
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'MeldingEditView',
  data() {
    return {
      opmerking: ''
    }
  },
  watch: {
    opmerking(newValue) {      
      const meldingStore = useMeldingStore();
      var selectedMelding = meldingStore.getSelectedMelding();  
      selectedMelding.opmerking = newValue;
      meldingStore.setSelectedMelding(selectedMelding);
    }
  },
  mounted() {
    const meldingStore = useMeldingStore();
    this.melding = meldingStore.getSelectedMelding(); 
    this.opmerking = this.melding.memo;  
  },
  methods: {
    previous() {
      this.$router.push('/today')
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.form-label {
  margin-bottom: 0;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar-previous {
  margin-bottom: calc(env(safe-area-inset-bottom) + 26px);
}
</style>