import { defineStore } from 'pinia';

export const usePermissionStore = defineStore({
    id: 'permission',
    state: () => ({
        locationPermissionEnabled: localStorage.getItem('locationPermissionEnabled')
    }),
    actions: {
        enableLocationPermission() {
            this.locationPermission = true;
            
            localStorage.setItem('locationPermissionEnabled', true);
        },
        isLocationPermissionEnabled() {
            return this.locationPermissionEnabled;
        }
    }
});